var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-popup',{staticClass:"flm-modal",attrs:{"title":_vm.title,"active":_vm.$attrs.value,"class-content":"popup-example"},on:{"close":_vm.onClose}},[_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("URL รายละเอียดโครงการ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-direct-phone"}},[_c('vs-input',{staticClass:"w-full",attrs:{"name":"project-direct-phone","disabled":""},model:{value:(_vm.urlProject),callback:function ($$v) {_vm.urlProject=$$v},expression:"urlProject"}})],1)],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("เบอร์โทรสายตรงโครงการ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-direct-phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"project-direct-phone","disabled":""},model:{value:(_vm.project.direct_telephone),callback:function ($$v) {_vm.$set(_vm.project, "direct_telephone", $$v)},expression:"project.direct_telephone"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุ เบอร์โทรสายตรงโครงการ")])]}}])})],1)],1),_vm._l((_vm.flm),function(item,index){return _c('FormLayout',{key:index},[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ทำเลที่ตั้งสำหรับ FLM "+_vm._s(index + 1))])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-direct-flm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":"project-location-flm","icon":"arrow_downward","disabled":"","autocomplete":""},model:{value:(_vm.flm[index]),callback:function ($$v) {_vm.$set(_vm.flm, index, $$v)},expression:"flm[index]"}},_vm._l((_vm.zones),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.zone_title}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุ ทำเลที่ตั้งสำหรับ FLM")])]}}],null,true)})],1)],1)}),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ชื่อ-นามสกุลเจ้าหน้าที่ฝ่ายขายประจำโครงการ")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-sale-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"project-sale-name","disabled":""},model:{value:(_vm.project.sale_name),callback:function ($$v) {_vm.$set(_vm.project, "sale_name", $$v)},expression:"project.sale_name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุ ข้อมูลเจ้าหน้าที่ฝ่ายขาย")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("รหัสบริษัท(DL200)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-company-code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"project-company-code","disabled":""},model:{value:(_vm.project.company_code),callback:function ($$v) {_vm.$set(_vm.project, "company_code", $$v)},expression:"project.company_code"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุรหัสบริษัท")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("รหัสโครงการ(DL200)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"project-code","disabled":""},model:{value:(_vm.project.project_code),callback:function ($$v) {_vm.$set(_vm.project, "project_code", $$v)},expression:"project.project_code"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุรหัสโครงการ")])]}}])})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุค่าส่วนกลาง")])]),_c('FormContent',{staticClass:"flex space-x-4"},[_c('vs-input',{staticClass:"w-full",attrs:{"disabled":""},model:{value:(_vm.project.common_fee),callback:function ($$v) {_vm.$set(_vm.project, "common_fee", $$v)},expression:"project.common_fee"}}),_c('vs-select',{staticClass:"selectExample",attrs:{"icon":"arrow_downward","autocomplete":"","disabled":""},model:{value:(_vm.project.common_fee_unit),callback:function ($$v) {_vm.$set(_vm.project, "common_fee_unit", $$v)},expression:"project.common_fee_unit"}},_vm._l((_vm.unitPriceList),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.title,"text":item.title}})}),1)],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชำระล่วงหน้าถึงวันที่")])]),_c('FormContent',[_c('datepicker',{staticClass:"w-full",attrs:{"disabled":""},model:{value:(_vm.commonFeeDate),callback:function ($$v) {_vm.commonFeeDate=$$v},expression:"commonFeeDate"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }