import useNotify from '@/use/useNotify'
import get from 'lodash/get'
/* eslint-disable-next-line */
export default (ctx) => {
  const notify = useNotify(ctx)
  /* eslint-disable-next-line */
  const {
    root: { $vs },
  } = ctx

  const activeUser = ctx.root.$store.state.AppActiveUser
  const email = get(activeUser, 'email', '')

  const deleteItem = (id) => {
    $vs.dialog({
      type: 'confirm',
      color: 'danger',
      title: 'Confirm',
      text: 'ต้องการลบข้อมูลหรือไม่',
      acceptText: 'ตกลง',
      cancelText: 'ยกเลิก',
      accept: () => {
        ctx.root.$store
          .dispatch('project/delete', { uuid: id, email })
          .then(() => {
            ctx.root.$store.dispatch('project/fetchDataListItems')
            notify.success({
              title: 'ลบข้อมูล',
              text: 'ลบข้อมูลเรียบร้อยแล้ว',
            })
            ctx.root.$router.push({ name: 'property-asset-project' })
          })
          .catch((error) => {
            console.log(error)
            notify.error({
              title: 'ลบข้อมูล',
              text: 'Something went wrong, please see console log.',
            })
          })
      },
    })
  }

  return {
    deleteItem,
  }
}
