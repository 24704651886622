<template>
  <div 
    id="data-list-list-view" 
    class="data-list-container">
    <!-- <PopupLanguage 
      v-model="isPopupCreateOpen" 
      :on-click-btn="redirectToCreatePage" /> -->
    <PopupLanguage
      v-model="isPopupDuplicateOpen"
      :hidden-lang="hiddenLang"
      :on-click-btn="duplicateProject"
      :on-close-btn="onCloseBtn"
    />
    <ProjectFlmModal 
      :project="projectData" 
      v-model="isFlmModal"/>
    <custom-table
      ref="table"
      :searchKeys="searchKeys"
      :max-items="itemsPerPage"
      :data="projects"
      pagination
      search
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div
          class="flex flex-wrap-reverse items-center data-list-btn-container"
        >
          <vs-button
            :disabled="!canCreateProject"
            color="primary" type="border"
            class="p-3 mb-4 mr-4 rounded-lg flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="redirectToCreatePage"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">กำหนดข้อมูลโครงการ</span>
          </vs-button>
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer mb-4 mr-4"
          >
            <span class="cursor-pointer flex items-center i18n-locale">
              <img 
                :src="i18n_locale_img" 
                :alt="lang" 
                class="h-4 w-5" >
              <span class="hidden sm:block ml-2 uppercase">{{
                $store.state.locale.currentLang
              }}</span>
            </span>
            <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
              <vs-dropdown-item @click="updateLang('th')">
                <img 
                  class="h-4 w-5 mr-1" 
                  src="@/assets/images/flags/th.png" 
                  alt="th" >
                &nbsp;Thai
              </vs-dropdown-item>
              <vs-dropdown-item @click="updateLang('en')">
                <img 
                  class="h-4 w-5 mr-1" 
                  src="@/assets/images/flags/en.png" 
                  alt="en" >
                &nbsp;English
              </vs-dropdown-item>
              <vs-dropdown-item @click="updateLang('cn')">
                <img 
                  class="h-4 w-5 mr-1" 
                  src="@/assets/images/flags/cn.png" 
                  alt="cn" >
                &nbsp;Chinese
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <!-- ITEMS PER PAGE -->
        <div>
          <vs-dropdown 
            vs-trigger-click 
            class="cursor-pointer mr-4 mb-4 items-per-page-handler">
            <div
              class="project-filter-dropdown p-2 px-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="m-auto">
                {{ filterByGroup ? filterByGroup : 'เลือกกลุ่ม' }}
              </span>
              <feather-icon 
                icon="ChevronDownIcon" 
                svg-classes="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="(group, index) in groupList"
                :key="index"
                @click="() => {
                  if ($refs.table) {
                    $refs.table.currentx = 1
                  }
                  filterByGroup = group.value
                }
              ">
                <span>{{group.label}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-dropdown 
            vs-trigger-click 
            class="cursor-pointer mr-4 items-per-page-handler">
            <div
              class="project-filter-dropdown p-2 px-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="m-auto">
                {{ filterByProjectStatus.label }}
              </span>
              <feather-icon 
                icon="ChevronDownIcon" 
                svg-classes="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="(status, index) in projectStatus"
                :key="index"
                @click="() => {
                  if ($refs.table) {
                    $refs.table.currentx = 1
                  }
                  filterByProjectStatus = status
                }
              ">
                <span>{{status.label}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <vs-dropdown 
            vs-trigger-click 
            class="cursor-pointer mr-4 items-per-page-handler">
            <div
              class="project-filter-dropdown p-2 px-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="m-auto">
                {{ filterByDataStatus.label }}
              </span>
              <feather-icon 
                icon="ChevronDownIcon" 
                svg-classes="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="(status, index) in dataStatus"
                :key="index"
                @click="() => {
                  $refs.table.currentx = 1
                  filterByDataStatus = status
                }
              ">
                <span>{{status.label}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <LhDropdownPerPage 
            :items="projects" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => {
              if ($refs.table) {
                $refs.table.currentx = 1
              }
              itemsPerPage = page
            }"
          /> 
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="title">ชื่อโครงการ</vs-th>
        <vs-th sort-key="username">กลุ่ม</vs-th>
        <vs-th sort-key="zone">ทำเล</vs-th>
        <vs-th sort-key="project_status">สถานะโครงการ</vs-th>
        <vs-th sort-key="status">สถานะข้อมูล</vs-th>
        <vs-th sort-key="published_at">ออนไลน์ข้อมูลวันที่</vs-th>
        <vs-th sort-key="updated_at">แก้ไขข้อมูลล่าสุดวันที่</vs-th>
        <vs-th sort-key="status">ข้อมูลสำหรับ FLM</vs-th>
        <!-- <vs-th sort-key="modified_at">Modified At</vs-th> -->
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-name font-medium">{{ tr.title }}</p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-concept">{{ tr.username }}</p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-priority">{{ tr.zone }}</p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-priority">{{ getProjectStatus(tr.project_status) }}</p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <vs-chip
                :color="getProjectStatusColor(tr.status)"
                class="product-order-status"
              >{{ tr.status | title }}</vs-chip
              >
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-priority">
                {{ tr.published_date ? publishedDateFormatter(tr.published_date) : '-' }}
              </p>
            </td>
            <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="project-priority">{{ customFormatter(tr.updated_at) }}</p>
            </td>
            <vs-td class="cursor-default">
              <a 
                href="#" 
                class="open-modal"
                @click.stop="openFlmModal(tr.uuid)">Show</a>
            </vs-td>

            <vs-td class="whitespace-no-wrap cursor-default">
              <feather-icon
                class="cursor-pointer"
                v-if="canDo"
                icon="CopyIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="duplicateData(tr.uuid, tr.id)"
              />
              <router-link
                :to="{ name: 'property-asset-project-edit', params: { id: tr.id }}"
                class="color-text-link hover:text-primary"
                @click.native="$event.stopImmediatePropagation()"
              >
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </router-link>
              <feather-icon
                v-if="canDo"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2 cursor-pointer"
                @click.stop="useProjectUtil.deleteItem(tr.uuid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
import PopupLanguage from '@/components/PopupLanguage'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'

import useProjectUtil from '@/use/useProjectUtil'
import useDatepicker from '@/use/useDatepicker'

import ProjectFlmModal from './ProjectFlmModal'

import { buildProjectBody, buildFormProjectData } from '@/helper/Utils'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'

import CustomTable from '@/components/CustomTable'

export default {
  name: 'ProjectListView',
  setup(props, ctx) {
    return {
      useProjectUtil: useProjectUtil(ctx),
      useDatepicker: useDatepicker(ctx),
    }
  },
  components: {
    PopupLanguage,
    ProjectFlmModal,
    LhDropdownPerPage,
    CustomTable
  },
  data() {
    return {
      searchKeys: ['title', 'username', 'zone', 'project_status', 'status'],
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      isPopupCreateOpen: false,
      isPopupDuplicateOpen: false,
      isFlmModal: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      lang: 'th',
      parentProjectUUID: null,
      projectUUID: null,
      hiddenLang: [],
      filterByGroup: null,
      filterByProjectStatus: {
        value: null,
        label: 'เลือกดูตามสถานะโครงการ'
      },
      filterByDataStatus: {
        value: null,
        label: 'เลือกดูตามสถานะข้อมูล'
      },
      projectStatus: [
        {
          value: null,
          label: 'เลือกดูตามสถานะโครงการ'
        },
        {
          value: 'new_project',
          label: 'โครงการใหม่'
        },
        {
          value: 'closed',
          label: 'ปิดการขาย'
        },
        {
          value: 'not_specified',
          label: 'ไม่ระบุ'
        }
      ],
      dataStatus: [
        {
          value: null,
          label: 'เลือกดูตามสถานะข้อมูล'
        },
        {
          value: 'published',
          label: 'Published'
        },
        {
          value: 'for_approve',
          label: 'For Approve'
        },
        {
          value: 'for_flm',
          label: 'For FLM'
        }
      ]
    }
  },
  computed: {
    canDo() {
      return this.$acl.check('editor')
    },
    canCreateProject() {
      return this.canDo && this.$store.state.locale.currentLang === 'th'
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    projects() {
      const user = this.$store.state.AppActiveUser
      const groupName = get(user, 'groupName', [])

      const projectList = this.$store.state.project.projects.map((project) => {      
        if (((!this.$acl.check('editor') && groupName.includes(project.username)) || this.$acl.check('editor'))
        && (!this.filterByGroup || (this.filterByGroup && this.filterByGroup === project.username))
        && (!this.filterByProjectStatus.value || (this.filterByProjectStatus.value && this.filterByProjectStatus.value === project.project_status) || (this.filterByProjectStatus.value === 'not_specified' && isEmpty(project.project_status)))
        && (!this.filterByDataStatus.value || (this.filterByDataStatus.value && this.filterByDataStatus.value === project.status))) {
          return {
            ...project,
            project_status: (!project.project_status || project.project_status === 'not_specified') ? 'active' : project.project_status
          }
        }
      })
      
      // if (this.$acl.check('editor')) {
      //   return projectList
      // }
      
      // return projectList.filter(
      //   (project) => project.username === groupName
      // )
      return projectList.filter((project) => project)
    },
    groupList () {
      if (!this.$acl.check('editor')) {
        const user = this.$store.state.AppActiveUser
        const groupNameList = get(user, 'groupName', [])
        const groups = groupNameList.map((groupName) => {
          return {
            value: groupName,
            label: groupName
          }
        })
        const orderGroup = orderBy(groups, ['label'], ['asc'])
        orderGroup.unshift({ value: null, label: 'เลือกกลุ่ม'})
        return orderGroup
      } else {
        const groupList = this.$store.state.project.projects.map((project) => {
          return {
            value: project.username,
            label: project.username
          }
        })
        const uniqGroup = uniqBy(groupList, 'value')
        const orderGroup = orderBy(uniqGroup, ['label'], ['asc'])
        orderGroup.unshift({ value: null, label: 'เลือกกลุ่ม'})
        
        return orderGroup
      }
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.projects.length
    },
    i18n_locale_img() {
      return require(`@/assets/images/flags/${this.$store.state.locale.currentLang}.png`)
    },
    projectData() {
      const uuid = this.projectUUID
      if (uuid) {
        const project = this.projects.find((project) => project.uuid === uuid)
        // project.location_flm = !isEmpty(project.location_flm) ? project.location_flm.split(',') : []
        return {
          ...project,
          location_flm: !isEmpty(project.location_flm) ? project.location_flm.split(',') : []
        }
      }
      return {}
    }
  },
  async created () {
    await this.$vs.loading()
    await this.$store.dispatch('category/fetchDataListItems')
    await this.$store.dispatch('project/fetchDataListItems')
    await this.$vs.loading.close()
    
    this.$store.dispatch('zone/fetchZoneListItems')
    this.$store.dispatch('page/fetchPages')
    this.$store.dispatch('geo/fetchProvinceListItems')
    this.$store.dispatch('geo/fetchDistrictListItems')
    this.$store.dispatch('geo/fetchSubDistrictListItems')
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    getProjectStatus(status) {
      switch (status) {
        case "new_project": 
          return "New Project"
        case "closed": 
          return "Sold out"
        default:
          return "Active"
      }
    },
    addNewData() {
      this.isPopupCreateOpen = true
      // this.$router.push({name: 'property-asset-project-create' })
    },
    duplicateData(projectUUid, projectId) {
      this.$store
        .dispatch('project/fetchExistProjectLang', projectId)
        .then((existLang) => {
          this.isPopupDuplicateOpen = true
          this.parentProjectUUID = projectUUid
          this.hiddenLang = existLang
        })

      
      // this.$router.push({name: 'property-asset-project-create' })
    },
    onCloseBtn() {
      this.parentProjectUUID = null
    },
    deleteData(id) {
      this.$store
        .dispatch('project/removeItem', id)
        .then(() => this.$store.dispatch('page/fetchPages'))
        .catch((err) => {
          console.error(err)
        })
    },
    editData(projectId) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.$router.push({
        name: 'property-asset-project-edit',
        params: { id: projectId },
      })
    },
    getProjectStatusColor(status) {
      if (status === 'inactive') return 'warning'
      if (status === 'active') return 'success'
      // if (status === 'canceled')  return 'danger'
      return 'primary'
    },
    getPopularityColor(num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    isShowItemPerPage(numData, pageIndex) {
      return numData >= pageIndex
    },
    customFormatter(date) {
      return this.useDatepicker.moment(date).format('DD MMM YYYY')
    },
    publishedDateFormatter(date) {
      return this.useDatepicker.moment(date, 'DD/MM/YYYY').format('DD MMM YYYY')
    },
    async updateLang(lang) {
      this.$refs.table.currentx = 1
      this.$vs.loading()
      await this.$store.dispatch('locale/changeLang', lang)
      await this.$store.dispatch('project/fetchDataListItems')
      await this.$store.dispatch('promotion/fetch')
      await this.$store.dispatch('project/fetchProjectName')  
      this.$vs.loading.close()
    },
    redirectToCreatePage() {
      const lang = this.$store.state.locale.currentLang
      this.$store.dispatch('locale/changeLang', lang)
      this.$router.push({ name: 'property-asset-project-create' })
    },
    duplicateProject(lang) {
      if (this.parentProjectUUID) {
        this.$store.dispatch('project/fetchItemByUUID', this.parentProjectUUID).then(() => {
          const productDetail = get(this.$store.state, ['project', 'project'], {})
          const categories = get(this.$store.state, ['category', 'categories'], [])
          const pages = get(this.$store.state, ['page', 'pages'], [])
          
          const initialFormData = {
            basicInfo: {
              title: null,
              userId: null,
              brandId: null,
              usrls: [],
              slug: null
            },
            zoneList: [{}],
            info: {},
            contact: {
              officeDay: [1, 2, 3, 4, 5, 6, 7], // all day mon - sun
            },
            location: {
              facilityList: [{}],
            },
            seo: {},
            admin: {},
            property: {
              propertyTypes: [],
              propertyList: [],
              pageLinks: [],
            },
            map: {},
            leadImage: {
              lead_image: [],
            },
            gallery: {
              gallery: [],
            },
            videoInfo: {},
            masterPlan: {
              master_plan: [],
            },
            unitPlan: {
              unitPlanList: [{}],
            },
            propertyInfo: {
              progressList: [{}],
            },
            propertyFacility: {
              propertyFacilityList: [{}],
            },
            progressInfo: {
              progressList: [{}],
            },
            banner: {
              is_event: false,
              page_url: {
                value: 'project',
              },
              event: {
                event_desktop_banner: [],
                event_mobile_banner: [],
                event_background_color: '',
                event_activity_image: [],
                event_activity_content: '',
                event_type: 'gallery',
                event_video_type: 'video_youtube',
                event_video_url: '',
                event_video_thumbnail: '',
                event_video_file: [],
                event_start_date: '',
                event_end_date: '',
              },
            },
          }
          const formData = buildFormProjectData(
            initialFormData,
            productDetail,
            productDetail.lang,
          )

          // assign id
          formData.id = productDetail.id
          formData.uuid = productDetail.uuid
          formData.slug = productDetail.slug
          formData.project_original_language = productDetail.lang
          formData.property = {
            ...formData.property,
            pageLinks: [],
          }

          // get old page link ids
          const oldLinkIds = []

          if (productDetail.project_page_links) {
            productDetail.project_page_links.forEach((link) => {
              oldLinkIds.push(link.page_id)
            })
          }

          // add created_by user
          const user = this.$store.state.AppActiveUser
          const email = get(user, 'email', null)
          formData.created_by = email
          formData.updated_by = email

          const linkUrl = []

          this.$vs.loading()
          this.$store.dispatch('page/fetchCategoryPageByLang', productDetail.lang)
            .then((resultPages) => {
              // get old page links
              resultPages.forEach((page) => {
                if (oldLinkIds.includes(page.id)) linkUrl.push(page.url)
              })
            })
            .then(() => this.$store.dispatch('locale/changeLang', lang))
            .then(() => this.$store.dispatch('page/fetchCategoryPageByLang', lang))
            .then((resultPages) => { 
              // set page links
              const pageLinkIds = []

              resultPages.forEach((page) => {
                if (linkUrl.includes(page.url)) {
                  pageLinkIds.push(page.id)
                }
              })

              formData.property.pageLinks = pageLinkIds
            })
            .then(() => this.$store.dispatch('brand/fetchOne', productDetail.brand_id))
            .then((brand) => {
              return this.$store.dispatch('brand/fetchByLang', { id: brand.brand_lang_id, lang })
            })
            .then((brand) => {
              let normalizeData = buildProjectBody(formData, categories, pages, lang)

              // clear text field
              normalizeData = {
                ...normalizeData,
                brand_id: get(brand, [0, 'id'], null),
                status: "for_approve",
              }

              normalizeData.project_original_language = productDetail.lang

              normalizeData.concept = null
              normalizeData.facility = null
              normalizeData.highlight = null
              normalizeData.ocpb = null
              normalizeData.meta_keyword = null
              normalizeData.meta_description = null
              normalizeData.security = null
              normalizeData.parking = null
              normalizeData.location = null
              normalizeData.neighborhood = null
              
              const emptyKeys = [
                "concept",
                "facility",
                "highlight",
                "ocpb",
                "published_date",
                "security",
                "parking",
                "location",
                "neighborhood",
                "meta_title",
                "meta_keyword",
                "meta_description",
                "unit_name",
                "facility_name",
                "unit_plan_detail",
                "unit_plan_name",
                "property_facility_name",
                "property_facility_detail",
                "image_alt_text",
                "image_description",
                "image_detail"
              ]

              let stringJson = JSON.stringify(normalizeData)
              emptyKeys.forEach((key) => {
                const re = new RegExp(`(("${key}":").*?("))`, 'g')
                stringJson = stringJson.replace(re, `"${key}": null`) 
              })
              try {
                return JSON.parse(stringJson)
              } catch (e) {
                return normalizeData
              }
            })
            .then((normalizeData) => this.$store.dispatch(
              'project/addProject', omit(normalizeData, ['project_house_sell_links', 'project_house_sell_gallery_links'])))
            .then((id) => {
              this.$vs.loading.close()
              this.$router.push({ name: 'property-asset-project-edit', params: { id } })
            })
        })
      }
    },
    openFlmModal(uuid) {
      this.isFlmModal = true
      this.projectUUID = uuid
    },
  },
}
</script>

<style lang="scss">
  .project-filter-dropdown {
    display: flex;
    text-align: center;
    min-height: 3rem;
    min-width: 7rem;
  }
  .product-order-status {
    text-align: center;
    font-weight: bold;
  }
  .open-modal {
    color: rgba(var(--vs-primary), .75) !important;
    &:hover {
      color: rgba(var(--vs-primary), 1) !important;
    }
  }
</style>
