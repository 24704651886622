<template>
  <vs-popup
    :title="title"
    :active="$attrs.value"
    class-content="popup-example"
    class="flm-modal"
    @close="onClose"
  >
    <FormLayout>
      <FormLabel>
        <span class="required">URL รายละเอียดโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          name="project-direct-phone"
        >
          <vs-input
            v-model="urlProject"
            name="project-direct-phone"
            class="w-full"
            disabled
          />
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">เบอร์โทรสายตรงโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-direct-phone"
          rules="required"
        >
          <vs-input
            v-model="project.direct_telephone"
            name="project-direct-phone"
            class="w-full"
            disabled
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ เบอร์โทรสายตรงโครงการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout
      v-for="(item, index) in flm"
      :key="index"
    >
      <FormLabel>
        <span class="required">ทำเลที่ตั้งสำหรับ FLM {{index + 1}}</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-direct-flm"
          rules="required"
        >
          <vs-select
            v-model="flm[index]"
            name="project-location-flm"
            class="selectExample"
            icon="arrow_downward"
            disabled
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in zones"
              :value="item.id"
              :text="item.zone_title"
              :key="index"
            />
          </vs-select>
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ ทำเลที่ตั้งสำหรับ FLM</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">ชื่อ-นามสกุลเจ้าหน้าที่ฝ่ายขายประจำโครงการ</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-sale-name" 
          rules="required">
          <vs-input
            v-model="project.sale_name"
            name="project-sale-name"
            class="w-full"
            disabled
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุ ข้อมูลเจ้าหน้าที่ฝ่ายขาย</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">รหัสบริษัท(DL200)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider
          v-slot="{ errors }"
          name="project-company-code"
          rules="required"
        >
          <vs-input
            v-model="project.company_code"
            name="project-company-code"
            class="w-full"
            disabled
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุรหัสบริษัท</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span class="required">รหัสโครงการ(DL200)</span>
      </FormLabel>
      <FormContent>
        <ValidationProvider 
          v-slot="{ errors }" 
          name="project-code" 
          rules="required">
          <vs-input
            v-model="project.project_code"
            name="project-code"
            class="w-full"
            disabled
          />
          <span 
            v-show="errors.length > 0" 
            class="text-danger text-sm"
          >กรุณาระบุรหัสโครงการ</span
          >
        </ValidationProvider>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ระบุค่าส่วนกลาง</span>
      </FormLabel>
      <FormContent class="flex space-x-4">
        <vs-input 
          v-model="project.common_fee" 
          class="w-full" 
          disabled/>
        <vs-select
          v-model="project.common_fee_unit"
          class="selectExample"
          icon="arrow_downward"
          autocomplete
          disabled
        >
          <vs-select-item
            v-for="(item, index) in unitPriceList"
            :value="item.title"
            :text="item.title"
            :key="index"
          />
        </vs-select>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ชำระล่วงหน้าถึงวันที่</span>
      </FormLabel>
      <FormContent>
        <datepicker
          v-model="commonFeeDate"
          class="w-full"
          disabled
        />
      </FormContent>
    </FormLayout>
  </vs-popup>
</template>

<script>
import get from 'lodash/get'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: 'PopupLanguage',
  components: {
    Datepicker,
  },
  props: {
    project: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: 'FLM Information',
    },
    onClickBtn: {
      type: Function,
      default: () => {},
    },
    onCloseBtn: {
      type: Function,
      default: () => {},
    }
  },
  computed: {
    unitPriceList() {
      return [
        {
          value: 'undefined',
          title: '',
        },
        {
          value: 1,
          title: 'หน่วย',
        },
        {
          value: 2,
          title: 'บาท/ตารางเมตร/เดือน',
        },
        {
          value: 3,
          title: 'บาท/ตารางวา/เดือน',
        },
        {
          value: 4,
          title: 'บาท/เดือน',
        },
      ]
    },
    zones() {
      return get(this.$store.state, ['zone', 'zones'], [])
    },
    commonFeeDate() {
      return this.dateFormatter(this.project.common_fee_date)
    },
    urlProject() {
      const title = get(this.project, 'title', '')
      return `${title.replace(/\s+/g, '-')}`
    },
    flm () {
      return get(this.project, 'location_flm', [])
    }
  },
  methods: {
    redirectToPage(lang) {
      this.$emit('input', false)
      this.onClickBtn(lang)
    },
    onClose(value) {
      this.$emit('input', value)
      this.onCloseBtn()
    },
    dateFormatter(date) {
      return moment(date, 'DD/MM/YYYY').format()
    }
  },
}
</script>

<style lang="css">
  @media (min-width: 768px){
    .flm-modal .vs-popup {
      width: 70%;
    }
  }
  @media (max-width: 767px){
    .flm-modal .vs-popup {
      width: 100%;
    }
  }
</style>