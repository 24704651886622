<template>
  <vs-popup
    :title="title"
    :active="$attrs.value"
    class-content="popup-example"
    @close="onClose"
  >
    <div class="vx-row flex justify-center">
      <div class="flex items-center space-x-4">
        <div>
          <vs-button 
            :disabled="hiddenLang.indexOf('th') > -1" 
            color="primary" 
            type="flat" 
            class="flex" 
            @click="redirectToPage('th')">
            <img 
              class="h-4 w-5 mr-2" 
              src="@/assets/images/flags/th.png" 
              alt="th" >Thai
          </vs-button>
        </div>
        <div>
          <vs-button 
            :disabled="hiddenLang.indexOf('en') > -1" 
            color="primary" 
            type="flat" 
            @click="redirectToPage('en')">
            <img 
              class="h-4 w-5 mr-2" 
              src="@/assets/images/flags/en.png" 
              alt="en" >English
          </vs-button>
        </div>
        <div>
          <vs-button 
            :disabled="hiddenLang.indexOf('cn') > -1" 
            color="primary" 
            type="flat" 
            @click="redirectToPage('cn')">
            <img 
              class="h-4 w-5 mr-2" 
              src="@/assets/images/flags/cn.png" 
              alt="cn" >Chinese
          </vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
export default {
  name: 'PopupLanguage',
  props: {
    title: {
      type: String,
      default: 'เลือกภาษาข้อมูล',
    },
    onClickBtn: {
      type: Function,
      default: () => {},
    },
    onCloseBtn: {
      type: Function,
      default: () => {},
    },
    hiddenLang: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    redirectToPage(lang) {
      this.$emit('input', false)
      this.onClickBtn(lang)
    },
    onClose(value) {
      this.$emit('input', value)
      this.onCloseBtn()
    },
  },
}
</script>
